
import { defineComponent, inject } from "vue";

export default defineComponent({
    name: "Table",
    components: {},
    props: ["propTableOptions", "propTableData", "mainTableOptions"],
    watch: {
        paginatorPageSize() {
            for (let row in this.originalList) {
                this.list[row] = this.originalList[row].slice(
                    0,
                    this.paginatorPageSize + 1
                );
            }
            this.calculateFromToListIndexes();
            return;
        },
    },
    setup() {
        const originUrl = window.location.origin;
        let checksModel: any = [{}];

        return {
            originUrl,
            checksModel,
        };
    },
    data() {
        const $mitt: any = null;

        const defaultTableOptions = this.propTableOptions;
        const tableOptions = this.propTableOptions;

        const headers = this.propTableData["headers"];
        const listOriginal = this.propTableData["listOriginal"];

        console.log(listOriginal);

        let list: any = [],
            tempArr: any = [],
            x = 0;
        for (let header in headers) {
            if (tableOptions[headers[header]].enabled) {
                console.log("is enabled");
                for (let row in listOriginal) {
                    tempArr.push(listOriginal[row][x]);
                }
            }

            if (tempArr[0] !== undefined) {
                list.push(tempArr);
            }

            tempArr = [];
            x++;
        }

        console.log(list);

        // const headers: string[] = Object.keys(test);
        // for (let header in headers) {
        //     if (test[header]) {
        //         console.log(test[header]);
        //     }
        // }

        // console.log(headers, list);

        const frozenLeft = this.mainTableOptions.frozenLeft;
        const frozenRight = this.mainTableOptions.frozenRight;

        const paginatorPageSize = 100,
            paginatorCurrentPage = 1,
            paginatorTotalPages = 8,
            paginatorButtons = 3;
        const tableRowHeight = this.mainTableOptions.tableRowHeight;

        const originalList = list.slice();

        for (let row in list) {
            list[row] = list[row].slice(0, paginatorPageSize + 1);
        }

        let currentResizingHeader: any;

        console.log("list", list);

        let mouseDownTimeoutID = -1;
        let alreadyClickedOnce = false;

        let tableBodyCheckboxes = [{}];
        let lastCheckedIndex: number | undefined;

        return {
            tableRowHeight,
            lastCheckedIndex,
            tableBodyCheckboxes,
            alreadyClickedOnce,
            mouseDownTimeoutID,
            defaultTableOptions,
            tableOptions,
            headers,
            list,
            listOriginal,
            originalList,
            frozenLeft,
            frozenRight,
            mouseDownLeft: -1,
            mouseDownRight: -1,
            mouseX: 0,
            $mitt,
            paginatorCurrentPage,
            paginatorPageSize,
            paginatorTotalPages,
            paginatorButtons,
            currentResizingHeader,
            isMouseDown: false,
            totalPaddingLeft: 0,
            totalPaddingRight: 0,
            renderCellsIndexFrom: 0,
            renderCellsIndexTo: 0,
            cellsMarginTop: 0,
            cellsMarginBottom: 0,
            currentResizeRightEvent: 0,
            currentResizeRightHeader: "",
            isCheckboxesEnabled: this.mainTableOptions.isCheckboxesEnabled,
            tableOverflow: 0,
        };
    },
    mounted() {
        this.$mitt = inject("$mitt");
        this.$mitt.emit("view-loading-finish");

        this.calculateFromToListIndexes();

        this.initHorizontalScroll();
        this.initVerticalScroll();
        document.addEventListener("mousemove", (e) => {
            this.mouseX = e.clientX;
        });
        document.addEventListener("mouseup", () => {
            this.handleRightResizeMouseUp();
            this.handleMouseUp();
        });
        document.addEventListener("mousedown", () => {
            this.handleMouseDown();
        });

        this.$mitt.on("table-options-update", (options) => {
            this.tableOptions = options;
            this.$forceUpdate();
        });

        this.calculateTotalPadding();
    },
    methods: {
        isPaginatorStart() {
            if (this.paginatorCurrentPage <= 2) {
                return true;
            }
            return false;
        },
        isPaginatorEnd() {
            if (
                this.paginatorCurrentPage === this.paginatorTotalPages ||
                this.paginatorCurrentPage - 1 === this.paginatorTotalPages
            ) {
                return true;
            }
            return false;
        },
        getPaginatorButtons() {
            if (this.isPaginatorEnd()) {
                return [
                    this.paginatorTotalPages - 2,
                    this.paginatorTotalPages - 1,
                    this.paginatorTotalPages,
                ];
            } else if (this.isPaginatorStart()) {
                return [1, 2, 3];
            } else {
                return [
                    this.paginatorCurrentPage - 1,
                    this.paginatorCurrentPage,
                    this.paginatorCurrentPage + 1,
                ];
            }
        },
        setPaginatorPage(idx: any) {
            if (this.paginatorCurrentPage !== idx) {
                this.paginatorCurrentPage = idx;
                return;
            }
            return;
        },
        previousPaginatorPage() {
            if (this.paginatorCurrentPage - 1 >= 1) {
                this.paginatorCurrentPage--;
            }
            return;
        },
        nextPaginatorPage() {
            if (this.paginatorTotalPages >= this.paginatorCurrentPage + 1) {
                this.paginatorCurrentPage++;
            }
            return;
        },
        getIsCurrentPaginatorButton(idx: any) {
            if (this.paginatorCurrentPage === idx) {
                return true;
            }
            return false;
        },
        setPaginatorPageSize(size: number) {
            if (this.paginatorPageSize === size) {
                return;
            }
            if (
                size === 50 ||
                size === 100 ||
                size === 150 ||
                size === 200 ||
                size === 250
            ) {
                this.paginatorPageSize = size;
            }
            return;
        },
        handleTableBodyCheckboxChange(e: any | Event) {
            const checkID = Number(
                e.target.value.replaceAll("table-checkbox-row-", "")
            );

            if (e.shiftKey) {
                if (this.lastCheckedIndex) {
                    for (let x = this.lastCheckedIndex; x > checkID; x--) {
                        this.tableBodyCheckboxes[0][x] =
                            !this.tableBodyCheckboxes[0][checkID];
                    }
                }
            } else {
                this.lastCheckedIndex = checkID;
            }
        },
        getLastTableBodyColumnName() {
            let tableBodyColumns: string[] = [];
            const tableOptionsKeys = Object.keys(this.tableOptions);
            if (tableOptionsKeys.length !== 0) {
                tableOptionsKeys.forEach((column, columnIndex) => {
                    if (
                        !this.isFrozenLeft(columnIndex, column) &&
                        !this.isFrozenRight(columnIndex, column)
                    ) {
                        tableBodyColumns.push(column);
                    }
                });
            }

            if (tableBodyColumns.length !== 0) {
                return tableBodyColumns[tableBodyColumns.length - 1];
            }
            return undefined;
        },
        handleTableResize() {
            const tableEl: HTMLElement | null =
                document.getElementById("table-body-wrapper");
            if (tableEl === null) {
                return;
            }
            const tableClientWidth: number | undefined = tableEl.clientWidth;

            const tableHeaderLeftEl: HTMLElement | null =
                document.getElementById("table-header-left");
            let tableHeaderLeftWidth = 0;
            if (tableHeaderLeftEl !== null) {
                tableHeaderLeftWidth = tableHeaderLeftEl.clientWidth;
            }
            const tableHeaderRightEl: HTMLElement | null =
                document.getElementById("table-header-right");
            let tableHeaderRightWidth = 0;
            if (tableHeaderRightEl !== null) {
                tableHeaderRightWidth = tableHeaderRightEl.clientWidth;
            }
            const tableBodyWrapperPadding =
                tableHeaderLeftWidth + tableHeaderRightWidth;

            const tableHeaderEl: HTMLElement | null =
                document.getElementById("table-header");
            let tableHeaderWidth = 0;
            if (tableHeaderEl !== null) {
                const children = tableHeaderEl.children;
                for (let i = 0; i < children.length; i++) {
                    const headerColumnEl = children[i];
                    tableHeaderWidth += headerColumnEl.clientWidth;
                }
            }

            const tableHeaderCheckboxColumnEl: HTMLElement | null =
                document.getElementById("table-header-checkbox-column");
            let checkboxOffsetPx = 0;
            if (
                tableHeaderCheckboxColumnEl !== null &&
                this.isCheckboxesEnabled
            ) {
                checkboxOffsetPx = tableHeaderCheckboxColumnEl.offsetWidth;
            }

            const tableBodyEl: HTMLElement | null =
                document.getElementById("table-body");
            let tableBodyWidth = 0;
            if (tableBodyEl !== null) {
                tableBodyWidth = tableBodyEl.offsetWidth;
            }

            console.log({
                tableClientWidth,
                tableHeaderLeftWidth,
                tableHeaderRightWidth,
                tableHeaderWidth,
                checkboxOffsetPx,
                tableBodyWidth,
            });

            const lastTableBodyColumnName = this.getLastTableBodyColumnName();

            // console.log({ lastTableBodyColumnName });

            const resizeBarOffset = this.isCheckboxesEnabled ? 9 : 3;

            if (lastTableBodyColumnName !== undefined) {
                this.tableOverflow =
                    tableHeaderWidth +
                    resizeBarOffset -
                    (tableClientWidth - tableBodyWrapperPadding);
                // console.log({ 'state': (tableClientWidth - tableBodyWrapperPadding) > tableHeaderWidth + resizeBarOffset });
                if (
                    tableClientWidth - tableBodyWrapperPadding >
                    tableHeaderWidth + resizeBarOffset
                ) {
                    // console.log({ 'width': Math.abs((tableClientWidth - tableBodyWrapperPadding) - tableHeaderWidth + resizeBarOffset) });
                    this.tableOptions[lastTableBodyColumnName].width =
                        Math.abs(
                            tableClientWidth -
                                tableBodyWrapperPadding -
                                (tableHeaderWidth -
                                    this.tableOptions[lastTableBodyColumnName]
                                        .width)
                        ) - resizeBarOffset;
                }
            }

            return;
        },
        calculateFromToListIndexes() {
            const tableEl: HTMLElement | null =
                document.getElementById("table-body-wrapper");
            if (tableEl === null) {
                return;
            }

            const cellHeight = this.tableRowHeight;

            const tableVisibleHeight = tableEl.clientHeight;

            const tableVerticalScrollOffset = tableEl.scrollTop;

            const cellsInVisibleHeight = Math.round(
                tableVisibleHeight / cellHeight
            );

            const visibleTableMiddleCellIndex =
                Math.abs(
                    this.paginatorPageSize -
                        Math.round(tableVerticalScrollOffset / cellHeight) -
                        this.paginatorPageSize
                ) + Math.round(cellsInVisibleHeight / 2);

            let renderCellsIndexFrom =
                visibleTableMiddleCellIndex - cellsInVisibleHeight;
            let renderCellsIndexTo =
                visibleTableMiddleCellIndex + cellsInVisibleHeight;
            if (renderCellsIndexFrom <= 0) {
                renderCellsIndexFrom = 0;
            }
            if (renderCellsIndexTo >= this.paginatorPageSize) {
                renderCellsIndexTo = this.paginatorPageSize;
            }
            if (renderCellsIndexTo <= renderCellsIndexFrom) {
                renderCellsIndexFrom = 0;
            }

            const cellsMarginTop = renderCellsIndexFrom * cellHeight;
            const cellsMarginBottom =
                Math.abs(renderCellsIndexTo - this.paginatorPageSize) *
                cellHeight;

            // let renderCellsIndexFrom, renderCellsIndexTo, cellsMarginTop, cellsMarginBottom;

            // const tableEl: HTMLElement | null = document.getElementById('table-body-wrapper');
            if (tableEl === null) {
                return;
            }
            // const tableVisibleHeight = tableEl.clientHeight;
            // const tableActualHeight = tableEl.scrollHeight;
            // const tableVerticalScrollOffset = tableEl.scrollTop;

            this.renderCellsIndexFrom = renderCellsIndexFrom;
            this.renderCellsIndexTo = renderCellsIndexTo;
            this.cellsMarginTop = cellsMarginTop;
            this.cellsMarginBottom = cellsMarginBottom;

            console.log({
                renderCellsIndexFrom,
                renderCellsIndexTo,
            });

            return;
        },
        calculateTotalPadding() {
            let totalPaddingLeft: any = 0,
                totalPaddingRight: any = 0;
            for (let idx = 0; idx < this.frozenLeft; idx++) {
                const width: any =
                    this.tableOptions[this.headers[idx]]["width"];
                totalPaddingLeft += width;
            }
            const reverseHeaders = this.headers.slice().reverse();
            for (let idx = 0; idx < this.frozenRight; idx++) {
                const width: any =
                    this.tableOptions[reverseHeaders[idx]]["width"];
                totalPaddingRight += width;
            }

            this.totalPaddingLeft = totalPaddingLeft;
            if (this.isCheckboxesEnabled) {
                this.totalPaddingLeft += 55.25;
            }
            this.totalPaddingRight = totalPaddingRight;

            return;
        },
        // handleHeaderMouseEnter(e: Event, header: string) {
        //     const resizeBar: HTMLElement | null = document.getElementById('resize-bar-' + header);

        //     if (resizeBar !== null && !this.isMouseDown) {
        //         resizeBar.style.borderRight = 'solid lightgray 2px';
        //     }

        //     return;
        // },
        // handleHeaderMouseLeave(e: Event, header: string) {
        //     const resizeBar: HTMLElement | null = document.getElementById('resize-bar-' + header);

        //     if (resizeBar !== null && !this.isMouseDown) {
        //         resizeBar.style.borderRight = '#e1e1e1';
        //     }

        //     return;
        // },
        handleMouseMoveResizeRight() {
            const e: any = this.currentResizeRightEvent;
            const header: string = this.currentResizeRightHeader;

            if (e.target === null) {
                return;
            }

            const diff =
                this.mouseX - e.target.getBoundingClientRect().right + 2.5;
            if (this.tableOptions[header].width + diff >= 50) {
                console.log({
                    TO: this.tableOverflow,
                });
                if (
                    this.tableOverflow + diff >= 0 &&
                    this.getLastTableBodyColumnName() === header
                ) {
                    this.tableOptions[header].width =
                        this.tableOptions[header].width + diff;
                } else if (this.getLastTableBodyColumnName() !== header) {
                    this.tableOptions[header].width =
                        this.tableOptions[header].width + diff;
                }
            } else {
                this.tableOptions[header].width = 50;
            }

            this.calculateTotalPadding();
            this.handleTableResize();

            return;
        },
        handleMouseMoveResizeLeft() {
            const e: any = this.currentResizeRightEvent;
            const header: string = this.currentResizeRightHeader;

            if (e.target === null) {
                return;
            }

            const diff =
                e.target.getBoundingClientRect().left + 2.5 - this.mouseX;
            if (this.tableOptions[header].width + diff >= 50) {
                this.tableOptions[header].width =
                    this.tableOptions[header].width + diff;
            }

            this.calculateTotalPadding();
            this.handleTableResize();

            return;
        },
        handleRightResizeMouseDown: function (e: any, header: string) {
            const resizeBar: HTMLElement | null = document.getElementById(
                "resize-bar-" + header
            );
            this.currentResizingHeader = header;

            clearInterval(this.mouseDownRight);
            if (resizeBar !== null) {
                if (this.alreadyClickedOnce) {
                    if (e.target === null) {
                        return;
                    }

                    this.tableOptions[header].width =
                        this.defaultTableOptions[header].width;
                    this.calculateTotalPadding();

                    return;
                } else {
                    this.mouseDownTimeoutID = window.setTimeout(() => {
                        if (this.isMouseDown) {
                            this.currentResizeRightEvent = e;
                            this.currentResizeRightHeader = header;
                            document.addEventListener(
                                "mousemove",
                                this.handleMouseMoveResizeRight,
                                true
                            );
                        } else {
                            return;
                        }
                    }, 150);
                }
            }
            return;
        },
        handleLeftResizeMouseDown(e: any, header: string) {
            const resizeBar: HTMLElement | null = document.getElementById(
                "resize-bar-" + header
            );
            this.currentResizingHeader = header;

            clearInterval(this.mouseDownRight);

            if (resizeBar !== null) {
                if (this.alreadyClickedOnce) {
                    if (e.target === null) {
                        return;
                    }

                    this.tableOptions[header].width =
                        this.defaultTableOptions[header].width;
                    this.calculateTotalPadding();

                    return;
                } else {
                    this.mouseDownTimeoutID = window.setTimeout(() => {
                        if (this.isMouseDown) {
                            this.currentResizeRightEvent = e;
                            this.currentResizeRightHeader = header;
                            document.addEventListener(
                                "mousemove",
                                this.handleMouseMoveResizeLeft,
                                true
                            );
                        } else {
                            return;
                        }
                    }, 150);
                }
            }
            return;
        },
        handleRightResizeMouseUp() {
            this.isMouseDown = false;

            clearInterval(this.mouseDownRight);

            document.removeEventListener(
                "mousemove",
                this.handleMouseMoveResizeRight,
                true
            );
            document.removeEventListener(
                "mousemove",
                this.handleMouseMoveResizeLeft,
                true
            );

            // const resizeBar: HTMLElement | null = document.getElementById('resize-bar-' + this.currentResizingHeader);

            // if (resizeBar !== null && !this.isMouseDown) {
            //     resizeBar.style.backgroundColor = 'unset';
            // }

            return;
        },
        handleMouseUp() {
            this.isMouseDown = false;
            clearTimeout(this.mouseDownTimeoutID);

            return;
        },
        handleMouseDown() {
            this.isMouseDown = true;
            this.alreadyClickedOnce = true;
            setTimeout(() => {
                this.alreadyClickedOnce = false;
            }, 298);

            return;
        },
        isEnabled(idx: number) {
            return this.tableOptions[this.headers[idx]]["enabled"];
        },
        // isAnyFrozenLeft() {
        //     for (let idx = 0; idx < this.headers.length; idx++) {
        //         if (this.isFrozenLeft(idx) === true) {
        //             return true;
        //         }
        //     }

        //     return false;
        // },
        // isAnyFrozenRight() {
        //     for (let idx = 0; idx < this.headers.length; idx++) {
        //         if (this.isFrozenRight(idx) === true) {
        //             return true;
        //         }
        //     }

        //     return false;
        // },
        isFrozenLeft(idx: number, title: string) {
            if (idx < this.frozenLeft) {
                return true;
            }

            return false;
        },
        isFrozenRight(idx: number, title: string) {
            if (idx > this.headers.length - 1 - this.frozenRight) {
                return true;
            }

            return false;
        },
        handleHorizontalScroll(table: HTMLElement) {
            const tableHeader: HTMLElement | null =
                document.getElementById("table-header");
            const leftBodyRowFrozen: HTMLElement | null =
                document.getElementById("table-body-left");
            const rightBodyRowFrozen: HTMLElement | null =
                document.getElementById("table-body-right");

            if (tableHeader !== null) {
                tableHeader.style.marginLeft = String(-table.scrollLeft) + "px";
            }

            return;

            // if (leftBodyRowFrozen !== null) {
            //     leftBodyRowFrozen.style.left = String(table.scrollLeft) + "px";
            // }
            // if (rightBodyRowFrozen !== null) {
            //     rightBodyRowFrozen.style.right = String(-table.scrollLeft) + "px";
            // }

            // return;
        },
        handleVerticalScroll(tableBody: HTMLElement) {
            this.calculateFromToListIndexes();

            const leftBodyRowFrozen: HTMLElement | null =
                document.getElementById("table-body-left-contents");
            const rightBodyRowFrozen: HTMLElement | null =
                document.getElementById("table-body-right-contents");

            // if (leftBodyRowFrozen !== null) {
            //     leftBodyRowFrozen.style.marginBottom = String(tableBody.scrollTop) + "px";
            // }
            // if (rightBodyRowFrozen !== null) {
            //     rightBodyRowFrozen.style.marginBottom = String(tableBody.scrollTop) + "px";
            // }

            return;
        },
        initHorizontalScroll() {
            const table: HTMLElement | null =
                document.getElementById("table-body-wrapper");

            if (table !== null) {
                table.addEventListener("scroll", () => {
                    this.handleHorizontalScroll(table);
                });
            }

            return;
        },
        initVerticalScroll() {
            const tableBody: HTMLElement | null =
                document.getElementById("table-body-wrapper");

            if (tableBody !== null) {
                tableBody.addEventListener("scroll", () => {
                    this.handleVerticalScroll(tableBody);
                });
            }

            return;
        },
    },
    beforeUnmount() {
        document.removeEventListener("mousemove", (e) => {
            this.mouseX = e.clientX;
        });
        document.removeEventListener("mouseup", () => {
            this.handleRightResizeMouseUp();
            this.handleMouseUp();
        });
        document.removeEventListener("mousedown", () => {
            this.handleMouseDown();
        });

        const table: HTMLElement | null =
            document.getElementById("table-body-wrapper");
        if (table !== null) {
            table.removeEventListener("scroll", () => {
                this.handleHorizontalScroll(table);
            });
        }

        const tableBody: HTMLElement | null =
            document.getElementById("table-body-wrapper");
        if (tableBody !== null) {
            tableBody.removeEventListener("scroll", () => {
                this.handleVerticalScroll(tableBody);
            });
        }

        return;
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-20bcf449")
const _hoisted_1 = {
  id: "kt_content_container",
  class: "container-xxl home-responsive-flex pt-10 pb-20"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Table, {
      propTableOptions: _ctx.tableOptions,
      propTableData: _ctx.tableData,
      mainTableOptions: _ctx.mainTableOptions
    }, null, 8, ["propTableOptions", "propTableData", "mainTableOptions"])
  ]))
}